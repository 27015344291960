"use client";

import styles from "./Header.module.scss";
import { Burger } from "./Burger/Burger";
import { Logo } from "./Logo/Logo";
import { useEffect, useState } from "react";
import Link from "next/link";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { MagneticChipButton } from "@/components/common/Buttons/MagneticChipButton/MagneticChipButton";
import { usePathname, useRouter } from "next/navigation";
import { LogoBlog } from "./LogoBlog/LogoBlog";
import { useContact } from "@/provider/ContextForm";
import { useTranslations } from "next-intl";
import { SearchBar } from "@/components/Blog/SearchBar/SearchBar";

gsap.registerPlugin(ScrollTrigger);

export const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [logoVisible, setLogoVisible] = useState(true);

  const { setIsContactOpen } = useContact();

  const translate = useTranslations("nav");
  const params = usePathname();
  const blogParams = params.includes("blog");
  const contactParams = params.includes("contact");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScroll(scrollPosition > 300);
      setLogoVisible(scrollPosition <= 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`${styles.headerContainer} ${blogParams ? styles.blog : ""} container-header`}
      >
        {blogParams ? (
          <Link href={`/blog`} aria-label="Back home">
            <div className={styles.logoBlog}>
              <LogoBlog />
            </div>
          </Link>
        ) : (
          <Link href={`/`} aria-label="Back home">
            <div className={`${styles.logoContainer} `}>
              <Logo isActive={isActive} hideLogo={logoVisible} />
            </div>
          </Link>
        )}
        <div className={styles.actionsContainer}>
          {!blogParams ? (
            !contactParams && (
              <MagneticChipButton
                onClick={() => setIsContactOpen(true)}
                label={translate("contact")}
                variant={isActive ? "active" : "accent"}
                hidden={true}
                magneticStrength={0.1}
              />
            )
          ) : (
            <>
              <ul className={styles.btnsMenu}>
                <li className={styles.hoverLine}>
                  <Link href="/">
                    <span>Tuxdi</span>
                    <div className={styles.arrowContainer}>
                      <div className={styles.arrow}></div>
                    </div>
                  </Link>
                </li>

                <li
                  className={styles.hoverLine}
                  onClick={() => setIsContactOpen(true)}
                >
                  {translate("contact")}
                </li>
              </ul>

              <SearchBar blogParams={blogParams} />
            </>
          )}
          <Burger
            isActive={isActive}
            setIsActive={setIsActive}
            setIsContactOpen={setIsContactOpen}
          />
        </div>
      </header>
    </>
  );
};
