import React, { useEffect, useTransition } from "react";
import styles from "./LanguageSwitch.module.scss";
import { useLocale } from "next-intl";
import { usePathname, useRouter } from "@/navigation";
import { useParams } from "next/navigation";
import { useRefStore } from "../../../../../../store/ref-store";

export default function LanguageSwitch() {
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const localActive = useLocale();
  const pathname = usePathname();
  const params = useParams();
  const isChecked = localActive === "en";

  const { slugLocale, setSlugLocale } = useRefStore((state) => ({
    slugLocale: state.slugLocale,
    setSlugLocale: state.setSlugLocale,
  }));

  const isBlogPost = pathname.startsWith("/blog/") && params?.post;

  useEffect(() => {
    if (!isBlogPost) {
      setSlugLocale("");
    }
  }, [isBlogPost, setSlugLocale]);

  const handleLanguageSwitch = (targetLocale) => {
    startTransition(() => {
      if (slugLocale && isBlogPost) {
        router.replace(
          // @ts-expect-error -- TypeScript will validate that only known
          { pathname: `/blog/${slugLocale}` },
          { locale: targetLocale }
        );
      } else if (!slugLocale && isBlogPost) {
        router.replace(
          // // @ts-expect-error -- TypeScript will validate that only known
          { pathname: `/blog` },
          { locale: targetLocale }
        );
      } else {
        // @ts-expect-error -- TypeScript will validate that only known
        router.replace({ pathname, params }, { locale: targetLocale });
      }
      setSlugLocale("");
    });
  };

  const handleClick = () => {
    const targetLocale = isChecked ? "es" : "en";
    handleLanguageSwitch(targetLocale);
  };

  return (
    <button
      disabled={isPending}
      onClick={handleClick}
      className={styles.buttonLanguage}
    >
      {isChecked ? "¡Español, por favor!" : "English, please!"}
    </button>
  );
}
